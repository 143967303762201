import React, { useEffect } from 'react'
import { Box } from '@mui/material'
import highlightText, { prepareHighlightWords } from 'utils/highlightText'
import PeoplePlaceholder from 'images/people_placeholder.png'
import { getStylesRightPeopleWidget } from 'styles/contents/resultpages/widgets/RightPeopleWidget'
import { getStylesRightWidget } from 'styles/contents/resultpages/widgets/RightWidget'
import { IPeopleResult } from 'components/models/PeopleResult'
import { IDeviceSetting } from 'utils/deviceSettings'
import createDOMPurify from 'dompurify'
import { getProfilePicture } from 'utils/msgraphProfilePicture'
import AuthStore from 'store/Auth'
import { Store } from 'store'
import { connect } from 'react-redux'
import TooltipTitle from 'components/contents/common/TooltipTitle'
import { ISynonymsApplied } from 'components/models/SynonymsApplied'
import HTMLEllipsis from 'react-lines-ellipsis/lib/html'
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC'

export interface RightPeopleWidgetResultProps {
  peopleResult: IPeopleResult
  index: number
  deviceSettings: IDeviceSetting
  searchQuery: string
  synonymsApplied: ISynonymsApplied[]
}

type AllProps = ReturnType<typeof mapStateToProps> &
  RightPeopleWidgetResultProps

function RightPeopleWidgetResult(props: AllProps): JSX.Element {
  const {
    peopleResult,
    index,
    deviceSettings,
    searchQuery,
    aadInfo,
    synonymsApplied
  } = props

  const DOMPurify = createDOMPurify(window)

  const [pictureUrl, setPictureUrl] = React.useState('')

  const rightWidgetClasses = getStylesRightWidget()
  const classes = getStylesRightPeopleWidget()

  const ResponsiveHTMLEllipsis = responsiveHOC()(HTMLEllipsis)

  const loadProfilePic = async () => {
    const pictureUrl = await getProfilePicture(
      DOMPurify.sanitize(peopleResult.userPrincipalName),
      DOMPurify.sanitize(peopleResult.pictureUrl),
      deviceSettings,
      aadInfo
    )
    setPictureUrl(pictureUrl)
  }

  useEffect(() => {
    loadProfilePic()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  let jobTitleToRender: string = peopleResult.jobTitle
    ? DOMPurify.sanitize(peopleResult.jobTitle)
    : ''
  if (!jobTitleToRender) {
    jobTitleToRender = peopleResult.department
      ? DOMPurify.sanitize(peopleResult.department)
      : ''
  }

  if (jobTitleToRender && peopleResult.country) {
    jobTitleToRender = `${highlightText(
      jobTitleToRender,
      prepareHighlightWords(searchQuery, jobTitleToRender, synonymsApplied),
      true
    ).trim()}, ${DOMPurify.sanitize(peopleResult.country)}`
  } else if (jobTitleToRender && peopleResult.officeNumber) {
    jobTitleToRender = `${highlightText(
      jobTitleToRender,
      prepareHighlightWords(searchQuery, jobTitleToRender, synonymsApplied),
      true
    ).trim()}, ${DOMPurify.sanitize(peopleResult.officeNumber)}`
  }

  return (
    <Box key={'rp' + index} className={rightWidgetClasses.widgetItem}>
      <Box key={'rpb1' + index} className={classes.widgetText}>
        <div key={'rpl1' + index}>
          <TooltipTitle
            title={DOMPurify.sanitize(peopleResult.displayName)}
            key={'rpd1' + index}
            singleLine={true}
            additionalClass={`${rightWidgetClasses.widgetTitle} ${classes.widgetTitle}`}
          />
        </div>
        <ResponsiveHTMLEllipsis
          className={rightWidgetClasses.widgetDescription}
          basedOn="words"
          unsafeHTML={jobTitleToRender}
          maxLine="2"
        />
      </Box>
      <Box key={'rpb2' + index} className={rightWidgetClasses.spacer} />
      <div
        key={'rpd3' + index}
        className={classes.widgetImage}
        style={{
          background: `url('${
            pictureUrl ? pictureUrl.split(' ').join('%20') : ''
          }'),url('${PeoplePlaceholder}')`
        }}
        role="img"
        aria-label={
          peopleResult.displayName && pictureUrl
            ? DOMPurify.sanitize(peopleResult.displayName)
            : ''
        }
      />
    </Box>
  )
}

const mapStateToProps = (state: Store) => {
  return {
    aadInfo: AuthStore.selectors.getAADInfo(state)
  }
}

export default connect(mapStateToProps)(RightPeopleWidgetResult)
