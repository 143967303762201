import React, { useState } from 'react'
import {
  Box,
  TableRow,
  TableCell,
  Collapse,
  IconButton,
  Tooltip,
  Link
} from '@mui/material'
import { FormattedMessage, useIntl } from 'react-intl'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { getFileTypeIconProps } from '@uifabric/file-type-icons'
import { Icon } from '@fluentui/react/lib/Icon'
import { FileMobileResult } from './FileMobileResult'
import {
  FileSourceResponse,
  getFileExtensionFromFileName,
  getPreviewFileUrlFallBack,
  isVideoOrPhoto
} from 'utils/o365'
import { stripHtml } from 'utils/string'
import { externalLinkClicked } from 'utils/tracking'
import highlightedText, { prepareHighlightWords } from 'utils/highlightText'
import { IDeviceSetting } from 'utils/deviceSettings'
import { dateFormatOptions } from 'constants/constants'
import { getStylesFileResult } from 'styles/results/FileResult'
import TooltipTitle from '../common/TooltipTitle'
import { ISynonymsApplied } from 'components/models/SynonymsApplied'
import LinesEllipsis from 'react-lines-ellipsis'
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC'
import DownloadIcon from '@mui/icons-material/Download'
import { downloadSPOBlob } from 'utils/o365'
import CircularProgress from '@mui/material/CircularProgress'
import { IAADState } from 'store/Auth/reducers'

interface FileResultProps {
  item: FileSourceResponse
  summary?: string
  isOpen: boolean
  searchQuery: string
  deviceSettings: IDeviceSetting
  documentId: string
  driveId: string
  fetchDocumentUrl: (
    documentId: string,
    driveId: string,
    fallBackUrl: string,
    openInNewTab: boolean
  ) => void
  index: number
  synonymsApplied: ISynonymsApplied[]
  aadInfo: IAADState
}

export function FileResult(props: FileResultProps): any {
  const {
    item,
    summary,
    searchQuery,
    isOpen,
    deviceSettings,
    documentId,
    driveId,
    fetchDocumentUrl,
    index,
    synonymsApplied,
    aadInfo
  } = props
  const [open, setOpen] = useState(isOpen)
  const [downloadInProgress, setDownloadInProgress] = useState(false)

  const classes = getStylesFileResult()
  const intl = useIntl()

  const ResponsiveEllipsis = responsiveHOC()(LinesEllipsis)

  if (!item) {
    return null
  }

  const fileExtension = getFileExtensionFromFileName(item.name)
  const validatedFileUrl = getPreviewFileUrlFallBack(item.webUrl, fileExtension)
  const fileUrl: string =
    isVideoOrPhoto(fileExtension) && documentId && driveId
      ? `${
          window.location.hash
        }&documentId=${documentId}&driveId=${driveId}&fallbackUrl=${encodeURIComponent(
          validatedFileUrl
        )}`
      : validatedFileUrl
  const handleDownload = () => {
    if (!isVideoOrPhoto(fileExtension)) {
      downloadSPOBlob(
        aadInfo,
        fileUrl.replace('?web=1', ''),
        '',
        item.name,
        setDownloadInProgress
      )
    } else {
      let listItemUrl = item.webUrl.split('/').slice(0, 5).join('/')
      if (item.parentReference && item.parentReference.sharepointIds) {
        listItemUrl +=
          "/_api/web/lists(guid'" +
          item.parentReference.sharepointIds.listId +
          "')/items(" +
          item.parentReference.sharepointIds.listItemId +
          ')/file'
      }
      downloadSPOBlob(
        aadInfo,
        '',
        listItemUrl,
        item.name,
        setDownloadInProgress
      )
    }
  }

  return (
    <React.Fragment>
      {deviceSettings.renderMobile ? (
        <FileMobileResult
          {...props}
          fileExtension={fileExtension}
          validatedFileUrl={validatedFileUrl}
          open={open}
          setOpen={setOpen}
          deviceSettings={deviceSettings}
          documentId={documentId}
          driveId={driveId}
          fetchDocumentUrl={fetchDocumentUrl}
        />
      ) : (
        <>
          <TableRow className={classes.fileResultRowHeader}>
            <TableCell className={classes.fileResultCollapseButton}>
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => setOpen(!open)}
              >
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            </TableCell>
            <TableCell>
              <Icon
                className={classes.fileResultIcon}
                {...getFileTypeIconProps({
                  extension: fileExtension,
                  size: 16
                })}
              />
            </TableCell>
            <TableCell className={classes.fileResultNameCell}>
              <Link
                underline={'hover'}
                className={classes.fileResultIcon}
                href={fileUrl}
                onClick={(event: any) => {
                  externalLinkClicked({
                    title: item.name,
                    url: validatedFileUrl,
                    index: index
                  })

                  if (documentId && driveId && isVideoOrPhoto(fileExtension)) {
                    fetchDocumentUrl(
                      documentId,
                      driveId,
                      validatedFileUrl,
                      deviceSettings.openLinksInNewTab
                        ? true
                        : event.button !== 0
                    )
                    event.preventDefault()
                  }
                }}
                {...(!deviceSettings.isMobile
                  ? {
                      onAuxClick: (event: any) => {
                        externalLinkClicked({
                          title: item.name,
                          url: validatedFileUrl,
                          index: index,
                          wasRightClicked: event?.button === 2 ? true : false
                        })

                        if (
                          documentId &&
                          driveId &&
                          isVideoOrPhoto(fileExtension) &&
                          event.button !== 2
                        ) {
                          fetchDocumentUrl(
                            documentId,
                            driveId,
                            validatedFileUrl,
                            true
                          )
                          event.preventDefault()
                        }
                      }
                    }
                  : {})}
                {...(deviceSettings.openLinksInNewTab === true
                  ? { target: '_blank', rel: 'noreferrer' }
                  : {})}
              >
                <TooltipTitle
                  title={item.name ? item.name.trim() : ''}
                  singleLine={true}
                />
              </Link>
            </TableCell>
            <TableCell className={classes.fileResultSource}>
              {' '}
              {!downloadInProgress && !item.webUrl.includes('-my.') && (
                <DownloadIcon
                  tabIndex={0}
                  onClick={handleDownload}
                  onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                      handleDownload()
                    }
                  }}
                  className={classes.downloadIcon}
                />
              )}
              {downloadInProgress && !item.webUrl.includes('-my.') && (
                <CircularProgress
                  className={classes.loadingSpinner}
                  size={14}
                />
              )}
            </TableCell>
            <TableCell className={classes.fileResultSource}>
              {item.webUrl
                ? item.webUrl.includes('-my.')
                  ? 'Personal'
                  : 'SharePoint'
                : ''}
            </TableCell>
            <TableCell className={classes.fileResultDate}>
              <div>
                {item.lastModifiedDateTime
                  ? intl.formatDate(
                      item.lastModifiedDateTime.replace('Z', ''),
                      dateFormatOptions
                    )
                  : ''}
              </div>
            </TableCell>
          </TableRow>
          <TableRow className={classes.fileContainer}>
            <TableCell
              colSpan={2}
              className={
                open ? classes.fileBodyCellOpen : classes.fileBodyCellCollapsed
              }
            ></TableCell>
            <TableCell
              colSpan={5}
              className={
                open ? classes.fileBodyCellOpen : classes.fileBodyCellCollapsed
              }
              style={{ maxWidth: 0 }}
            >
              <Collapse
                in={open}
                timeout="auto"
                unmountOnExit
                className={classes.resultCollapse}
              >
                <Box className={classes.fileResultBody}>
                  {summary ? (
                    highlightedText(
                      stripHtml(summary),
                      prepareHighlightWords(
                        searchQuery,
                        stripHtml(summary),
                        synonymsApplied
                      ),
                      false
                    )
                  ) : (
                    <FormattedMessage
                      id="missing_description_text"
                      defaultMessage="Description is not available"
                    />
                  )}
                </Box>

                <Box
                  className={classes.fileResultBodyPath}
                  style={{ marginTop: 6 }}
                >
                  <Tooltip enterTouchDelay={300} title={item.webUrl}>
                    <div>
                      <ResponsiveEllipsis
                        style={{
                          whiteSpace: 'pre-wrap',
                          textOverflow: 'ellipsis',
                          overflow: 'hidden'
                        }}
                        basedOn="words"
                        text={item.webUrl ? item.webUrl.trim() : ''}
                        maxLine="2"
                      />
                    </div>
                  </Tooltip>
                </Box>
              </Collapse>
            </TableCell>
          </TableRow>
        </>
      )}
    </React.Fragment>
  )
}
