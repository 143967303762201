import React, { useEffect } from 'react'
import {
  Typography,
  Box,
  Card,
  CardContent,
  CardActions,
  Button
} from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { removeQueryParam } from 'utils/queryParams'
import PeoplePlaceholder from 'images/people_placeholder.png'
import { getStylesLeftPeopleWidget } from 'styles/contents/resultpages/widgets/LeftPeopleWidget'
import { getStylesLeftWidget } from 'styles/contents/resultpages/widgets/LeftWidget'
import { IPeopleResult } from 'components/models/PeopleResult'
import { IDeviceSetting } from 'utils/deviceSettings'
import { useIntl } from 'react-intl'
import createDOMPurify from 'dompurify'
import { getProfilePicture } from 'utils/msgraphProfilePicture'
import AuthStore from 'store/Auth'
import { Store } from 'store'
import { connect } from 'react-redux'
import TooltipTitle from 'components/contents/common/TooltipTitle'
import { makeStyles } from '@mui/styles'
import EmailIcon from '@mui/icons-material/EmailOutlined'
import CallIcon from '@mui/icons-material/Call'
import ChatIcon from '@mui/icons-material/Chat'
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid'
import { getURIPhoneNumber } from 'utils/people'
import LinkedIn from 'images/linkedin.png'
import TeamsImage from 'images/teams.svg'

export interface LeftPeopleWidgetResultProps {
  peopleResult: IPeopleResult
  i: number
  deviceSettings: IDeviceSetting
}

type AllProps = ReturnType<typeof mapStateToProps> & LeftPeopleWidgetResultProps

function LeftPeopleWidgetResult(props: AllProps): JSX.Element {
  const { peopleResult, i, deviceSettings, aadInfo } = props

  const DOMPurify = createDOMPurify(window)

  const navigateFunction = useNavigate()
  const leftWidgetClasses = getStylesLeftWidget()
  const classes = getStylesLeftPeopleWidget()

  const intl = useIntl()

  const [pictureUrl, setPictureUrl] = React.useState('')
  const loadProfilePic = async () => {
    const pictureUrl = await getProfilePicture(
      DOMPurify.sanitize(peopleResult.userPrincipalName),
      DOMPurify.sanitize(peopleResult.pictureUrl),
      deviceSettings,
      aadInfo
    )
    setPictureUrl(pictureUrl)
  }

  useEffect(() => {
    loadProfilePic()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const userProfileStyles = makeStyles(() => ({
    peopleProfilepic: {
      width: '72px',
      height: '72px',
      borderRadius: '50%',
      backgroundImage: `url('${
        pictureUrl
          ? pictureUrl.replace('MThumb', 'LThumb').split(' ').join('%20')
          : ''
      }'),url('${PeoplePlaceholder}') !important`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center'
    },
    defaultProfilepic: {
      backgroundImage: 'url(' + PeoplePlaceholder + ')'
    }
  }))
  const userProfileClasses = userProfileStyles()

  let phoneUrl = null
  if (peopleResult.workPhone)
    phoneUrl = getURIPhoneNumber(
      DOMPurify.sanitize(peopleResult.workPhone),
      peopleResult.country
    )

  let mobilePhoneUrl = null
  if (peopleResult.mobilePhone)
    mobilePhoneUrl = getURIPhoneNumber(
      DOMPurify.sanitize(peopleResult.mobilePhone),
      peopleResult.country
    )

  const email = DOMPurify.sanitize(peopleResult.mail)
  const teamsUrl = `https://teams.microsoft.com/l/chat/0/0?users=${email}`
  const teamsCallUrl = `https://teams.microsoft.com/l/call/0/0?users=${email}`
  const linkedInUrl = `https://www.linkedin.com/search/results/all/?authorCompany=%5B%5D&authorIndustry=%5B%5D&contactInterest=%5B%5D&facetCity=%5B%5D&facetCompany=%5B%5D&facetConnectionOf=%5B%5D&facetCurrentCompany=%5B%5D&facetCurrentFunction=%5B%5D&facetGeoRegion=%5B%5D&facetGroup=%5B%5D&facetGuides=%5B%5D&facetIndustry=%5B%5D&facetNetwork=%5B%5D&facetNonprofitInterest=%5B%5D&facetPastCompany=%5B%5D&facetProfessionalEvent=%5B%5D&facetProfileLanguage=%5B%5D&facetRegion=%5B%5D&facetSchool=%5B%5D&facetSeniority=%5B%5D&facetServiceCategory=%5B%5D&facetState=%5B%5D&groups=%5B%5D&keywords=${DOMPurify.sanitize(
    peopleResult.displayName
  )}%20KPMG&origin=GLOBAL_SEARCH_HEADER&page=1&refresh=false&skillExplicit=%5B%5D&topic=%5B%5D`

  let jobTitle = peopleResult.jobTitle
    ? DOMPurify.sanitize(peopleResult.jobTitle)
    : ''
  if (jobTitle !== '' && peopleResult.department) jobTitle = jobTitle + ', '
  if (peopleResult.department)
    jobTitle = jobTitle + DOMPurify.sanitize(peopleResult.department)

  return (
    <div key={'lp' + i}>
      <Box key={'lpb1' + i} style={{ width: 20 }} />
      <div key={'lpl' + i}>
        <Card key={'lpc' + i} className={leftWidgetClasses.cardNoLink}>
          <div className={classes.profilepicWrapper}>
            <div
              className={
                userProfileClasses.peopleProfilepic +
                ' ' +
                userProfileClasses.defaultProfilepic
              }
              role="img"
              aria-label={
                peopleResult.displayName
                  ? DOMPurify.sanitize(peopleResult.displayName)
                  : ''
              }
            />
          </div>
          <CardContent
            key={'lpcc' + i}
            style={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              padding: 0
            }}
          >
            <Typography
              key={'lpt1' + i}
              variant="body1"
              color="primary"
              component="div"
              className={classes.peopleTitle}
            >
              <TooltipTitle
                title={DOMPurify.sanitize(peopleResult.displayName)}
                singleLine={true}
                key={'lpd2' + i}
              />
            </Typography>

            {jobTitle && (
              <Typography
                key={'lpd1' + i}
                variant="body1"
                component="div"
                className={classes.peopleContent}
              >
                <TooltipTitle title={jobTitle} key={'lpd2' + i} />
              </Typography>
            )}

            <Box key={'lpb2' + i} className={leftWidgetClasses.spacer} />

            <CardActions className={classes.peopleControls}>
              <Button
                classes={{
                  root: classes.iconButton
                }}
                size="small"
                color="primary"
                className={classes.peopleButton}
                href={`mailto:${email}`}
                title="Mail"
              >
                <EmailIcon className={classes.peopleIcon} />
              </Button>
              <Button
                size="small"
                color="primary"
                classes={{
                  root: classes.iconButton
                }}
                href={teamsUrl}
                onClick={() => removeQueryParam(navigateFunction, 'cntx')}
                target="_blank"
                rel="noreferrer"
                title={intl.formatMessage({
                  id: 'start_teams_chat',
                  defaultMessage: 'Start a teams chat'
                })}
              >
                <ChatIcon className={classes.peopleIcon} />
              </Button>
              <Button
                size="small"
                color="primary"
                classes={{
                  root: classes.iconButton
                }}
                href={teamsCallUrl}
                onClick={() => removeQueryParam(navigateFunction, 'cntx')}
                target="_blank"
                rel="noreferrer"
                title={intl.formatMessage({
                  id: 'start_teams_call',
                  defaultMessage: 'Start a teams call'
                })}
              >
                <div className={classes.iconTeamsCallWrapper}>
                  <img
                    src={TeamsImage}
                    alt="MicroSoft Teams logo"
                    width="20px"
                    className={classes.peopleTeams}
                  />
                  <CallIcon className={classes.iconTeamsCallOverlay} />
                </div>
              </Button>
              {phoneUrl && (
                <Button
                  size="small"
                  color="primary"
                  classes={{
                    root: classes.iconButton
                  }}
                  href={phoneUrl}
                  onClick={() => removeQueryParam(navigateFunction, 'cntx')}
                  target="_blank"
                  title={intl.formatMessage({
                    id: 'officelocationwidget_phone',
                    defaultMessage: 'Phone'
                  })}
                >
                  <CallIcon className={classes.peopleIcon} />
                </Button>
              )}
              {mobilePhoneUrl && (
                <Button
                  size="small"
                  color="primary"
                  classes={{
                    root: classes.iconButton
                  }}
                  href={mobilePhoneUrl}
                  onClick={() => removeQueryParam(navigateFunction, 'cntx')}
                  target="_blank"
                  title={intl.formatMessage({
                    id: 'mobile_phone',
                    defaultMessage: 'Mobile phone'
                  })}
                >
                  <PhoneAndroidIcon className={classes.peopleIcon} />
                </Button>
              )}
              <Button
                size="small"
                color="primary"
                classes={{
                  root: classes.iconButton
                }}
                href={linkedInUrl}
                onClick={() => removeQueryParam(navigateFunction, 'cntx')}
                target="_blank"
                title={'LinkedIn'}
              >
                <img
                  src={LinkedIn}
                  alt="LinkedIn logo"
                  width="20px"
                  className={classes.peopleLinkedIn}
                />
              </Button>
            </CardActions>
          </CardContent>
        </Card>
      </div>
    </div>
  )
}

const mapStateToProps = (state: Store) => {
  return {
    aadInfo: AuthStore.selectors.getAADInfo(state)
  }
}

export default connect(mapStateToProps)(LeftPeopleWidgetResult)
