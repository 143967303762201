import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

export function getStylesPeopleResult(): any {
  const renderStyles = getStyles()
  return renderStyles()
}

function getStyles() {
  return makeStyles((theme: Theme) => ({
    title: {
      fontSize: '20px',
      marginBottom: '3px',
      color: '#004e98',
      width: '100%',
      [`${theme.breakpoints.down(1024)}`]: {
        fontSize: '18px',
        lineHeight: '20px',
        marginBottom: '12px',
        fontWeight: '400',
        fontFamility: 'Roboto, HelveticaNeue, Arial, sans-serif'
      }
    },
    jobDetails: {
      fontSize: '16px',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      color: '#004e98',
      [`${theme.breakpoints.down(1024)}`]: {
        fontSize: '12px',
        fontFamility: 'Roboto, HelveticaNeue, Arial, sans-serif'
      }
    },
    location: {
      fontSize: '16px',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      [`${theme.breakpoints.down(1024)}`]: {
        fontSize: '12px',
        fontFamility: 'Roboto, HelveticaNeue, Arial, sans-serif'
      }
    },
    b: {
      color: '#3c4043',
      fontSize: 14
    },
    peopleCard: {
      display: 'flex',
      marginBottom: '16px',
      boxShadow: 'none',
      border: 'solid 1px #dfe1e5',
      borderRadius: 4,
      height: 155,
      [`${theme.breakpoints.down(1024)}`]: {
        border: 'none',
        backgroundColor: '#fff',
        margin: '0 0 10px 0',
        boxShadow: '0 1px 6px rgba(32, 33, 36, 0.28)',
        borderRadius: '4px'
      }
    },
    profilepicWrapper: {
      flex: '0 0 116px',
      display: 'flex',
      justifyContent: 'space-around',

      alignItems: 'center'
    },
    peopleContent: {
      flex: '1 0 auto',
      paddingBottom: '0'
    },
    peopleDetails: {
      display: 'flex',
      flex: '1',
      flexDirection: 'column',
      width: 'calc(100% - 116px)', //profilepicWrapper width
      [`${theme.breakpoints.down(1024)}`]: {
        width: '70%'
      }
    },
    peopleLinkedIn: {
      height: '19px',
      maxHeight: '19px'
    },
    iconTeamsCallWrapper: {
      display: 'inline-block',
      position: 'relative',
      height: '20px',
      width: '40px'
    },
    peopleTeams: {
      width: '13px',
      maxWidth: '13px',
      position: 'absolute',
      top: 0,
      right: 9
    },
    iconTeamsCallOverlay: {
      position: 'absolute',

      pointerEvents: 'none',
      height: '15px',
      maxWidth: '15px',
      bottom: 0,
      left: 9
    },
    peopleControls: {
      display: 'flex',
      paddingLeft: '0px',
      flexDirection: 'row',
      flexWrap: 'nowrap',
      alignItems: 'center',
      paddingTop: 0,
      [`${theme.breakpoints.down(420)}`]: {
        paddingLeft: '4px'
      }
    },
    peopleButton: {
      textTransform: 'none',
      fontWeight: 'normal',
      [`${theme.breakpoints.down(1024)}`]: {
        color: '#005eb8'
      }
    },
    peopleIcon: {
      height: '20px',
      maxWidth: '20px',
      [`${theme.breakpoints.down(1024)}`]: {
        color: '#005eb8'
      }
    },
    peopleBodyText: {
      paddingTop: '8px',
      [`${theme.breakpoints.down(1024)}`]: {
        fontSize: '12px'
      }
    },
    iconButton: {
      minWidth: '50px',
      justifyContent: 'flex-center',
      marginLeft: '0px !important',
      [`${theme.breakpoints.down(420)}`]: {
        minWidth: '40px'
      }
    },
    iconButtonSmall: {
      minWidth: '45px',
      justifyContent: 'flex-center',
      marginLeft: '0px !important',
      [`${theme.breakpoints.down(420)}`]: {
        minWidth: '35px'
      }
    },
    isVisitedResult: {
      color: '#609',
      '&:hover': {
        textDecoration: 'underline',
        textDecorationColor: '#609'
      }
    },
    link: {
      display: 'flex',
      flexDirection: 'row',
      flex: 1
    }
  }))
}
