import React, { useEffect } from 'react'
import EmailIcon from '@mui/icons-material/EmailOutlined'
import CallIcon from '@mui/icons-material/Call'
import ChatIcon from '@mui/icons-material/Chat'
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid'
import {
  Typography,
  Box,
  Card,
  CardContent,
  CardActions,
  Button
} from '@mui/material'
import { makeStyles } from '@mui/styles'
// import LazyLoad from 'react-lazyload'
import PeoplePlaceholder from 'images/people_placeholder.png'
import LinkedIn from 'images/linkedin.png'
import TeamsImage from 'images/teams.svg'
//import Theme from 'theme'
import * as stringUtils from 'utils/string'
import { getURIPhoneNumber } from 'utils/people'
import highlightedText, { prepareHighlightWords } from 'utils/highlightText'
import { removeQueryParam } from 'utils/queryParams'
import { useNavigate } from 'react-router-dom'
import { connect } from 'react-redux'
import FindNotification from 'components/contents/common/FindNotification'
import { getStylesPeopleResult } from 'styles/results/PeopleResult'
import { IDeviceSetting } from 'utils/deviceSettings'
import { getProfilePicture } from 'utils/msgraphProfilePicture'
import AuthStore from 'store/Auth'
import { Store } from 'store'
import TooltipTitle from '../common/TooltipTitle'
import { ISynonymsApplied } from 'components/models/SynonymsApplied'
import { useIntl } from 'react-intl'
import LinesEllipsis from 'react-lines-ellipsis'
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC'
import { countriesISO } from 'constants/countriesISO'

export interface PeopleResultProps {
  upn: string
  title: string
  body: string | null
  imageUrl: string | null
  jobTitle: string | null
  country: string | null
  firstName: string
  email: string | null
  department: string | null
  index?: any
  phoneNumber?: string
  mobileNumber?: string
  officeNumber: string | null
  searchQuery?: string
  deviceSettings: IDeviceSetting
  synonymsApplied: ISynonymsApplied[]
}

type AllProps = ReturnType<typeof mapStateToProps> & PeopleResultProps

function PeopleResult(props: AllProps): JSX.Element {
  const navigateFunction = useNavigate()
  const classes = getStylesPeopleResult()
  const intl = useIntl()
  const {
    upn,
    title,
    body,
    imageUrl,
    jobTitle,
    country,
    email,
    department,
    index,
    phoneNumber,
    mobileNumber,
    officeNumber,
    searchQuery,
    deviceSettings,
    aadInfo,
    synonymsApplied
  } = props

  const ResponsiveEllipsis = responsiveHOC()(LinesEllipsis)

  const [pictureUrl, setPictureUrl] = React.useState('')
  const [showMobileUsLinkMessage, setShowMobileUsLinkMessage] =
    React.useState(false)

  const userProfileStyles = makeStyles(() => ({
    peopleProfilepic: {
      width: '72px',
      height: '72px',
      borderRadius: '50%',
      backgroundImage: `url('${
        pictureUrl
          ? pictureUrl.replace('MThumb', 'LThumb').split(' ').join('%20')
          : ''
      }'),url('${PeoplePlaceholder}') !important`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center'
    },
    defaultProfilepic: {
      backgroundImage: 'url(' + PeoplePlaceholder + ')'
    }
  }))
  const userProfileClasses = userProfileStyles()

  let phoneUrl = null
  if (phoneNumber) phoneUrl = getURIPhoneNumber(phoneNumber, country)

  let mobilePhoneUrl = null
  if (mobileNumber) mobilePhoneUrl = getURIPhoneNumber(mobileNumber, country)

  const teamsUrl = `https://teams.microsoft.com/l/chat/0/0?users=${email}`
  const teamsCallUrl = `https://teams.microsoft.com/l/call/0/0?users=${email}`
  const linkedInUrl = `https://www.linkedin.com/search/results/all/?authorCompany=%5B%5D&authorIndustry=%5B%5D&contactInterest=%5B%5D&facetCity=%5B%5D&facetCompany=%5B%5D&facetConnectionOf=%5B%5D&facetCurrentCompany=%5B%5D&facetCurrentFunction=%5B%5D&facetGeoRegion=%5B%5D&facetGroup=%5B%5D&facetGuides=%5B%5D&facetIndustry=%5B%5D&facetNetwork=%5B%5D&facetNonprofitInterest=%5B%5D&facetPastCompany=%5B%5D&facetProfessionalEvent=%5B%5D&facetProfileLanguage=%5B%5D&facetRegion=%5B%5D&facetSchool=%5B%5D&facetSeniority=%5B%5D&facetServiceCategory=%5B%5D&facetState=%5B%5D&groups=%5B%5D&keywords=${title}%20KPMG&origin=GLOBAL_SEARCH_HEADER&page=1&refresh=false&skillExplicit=%5B%5D&topic=%5B%5D`

  const jobTitleWithoutCountry = (localJobTitle: string | null): string => {
    if (!localJobTitle) {
      return ''
    }
    return stringUtils.toTitleCase(localJobTitle.split(/\r?\n/)[0])
  }

  const loadProfilePic = async () => {
    const pictureUrl = await getProfilePicture(
      upn,
      imageUrl,
      deviceSettings,
      aadInfo
    )
    setPictureUrl(pictureUrl)
  }

  //format jobtitle, location
  const jobDetails: string[] = []
  let jobTitleToRender = jobTitleWithoutCountry(jobTitle)
  if (jobTitleToRender) {
    jobDetails.push(jobTitleToRender)
  }
  if (department) {
    jobDetails.push(department)
  }

  const location: string[] = []
  if (country) {
    const _country = country.toLocaleUpperCase() === 'UK' ? 'GB' : country
    const countryName = countriesISO.find(
      (item) => item.Code.toLocaleUpperCase() === _country.toLocaleUpperCase()
    )
    if (countryName && countryName.Name) {
      location.push(countryName.Name)
    } else {
      location.push(country)
    }
  }

  if (officeNumber) {
    location.push(officeNumber)
  }

  useEffect(() => {
    if (!deviceSettings.isMobile) {
      loadProfilePic()
    } else {
      setPictureUrl('')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (deviceSettings.isMobile) {
      loadProfilePic()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [upn])

  return (
    // <LazyLoad offset={150}>
    <Card className={classes.peopleCard} key={index}>
      <div className={classes.profilepicWrapper}>
        <div
          className={
            userProfileClasses.peopleProfilepic +
            ' ' +
            userProfileClasses.defaultProfilepic
          }
          role="img"
          aria-label={title}
        />
      </div>
      <Box className={classes.peopleDetails}>
        <CardContent className={classes.peopleContent}>
          <div className={classes.link}>
            <Typography
              variant="h5"
              color="primary"
              className={`${classes.title}`}
              component="div"
            >
              <TooltipTitle title={title ? title : ''} singleLine={true} />
            </Typography>
          </div>

          {jobDetails.length > 0 && (
            <Typography
              variant="body1"
              color="secondary"
              className={classes.jobDetails}
              //style={(Theme.palette as any).resultLink}
              component="div"
            >
              <ResponsiveEllipsis
                style={{
                  whiteSpace: 'pre-wrap',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden'
                }}
                basedOn="words"
                text={jobDetails.join(', ')}
                maxLine="1"
              />
            </Typography>
          )}

          {location.length > 0 && (
            <Typography
              variant="body1"
              color="secondary"
              className={classes.location}
              //style={(Theme.palette as any).resultLink}
              component="div"
            >
              <ResponsiveEllipsis
                style={{
                  whiteSpace: 'pre-wrap',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden'
                }}
                basedOn="words"
                text={location.join(', ')}
                maxLine="1"
              />
            </Typography>
          )}
          <Typography variant="body2" color="secondary" component="div">
            <TooltipTitle
              useCssOnly={true}
              singleLine={true}
              title={highlightedText(
                body ? body : '',
                prepareHighlightWords(
                  searchQuery,
                  body ? body : '',
                  synonymsApplied
                ),
                false
              )}
              additionalClass={classes.peopleBodyText}
            />
          </Typography>
        </CardContent>
        <CardActions className={classes.peopleControls}>
          {email && (
            <>
              <Button
                classes={{
                  root: classes.iconButton
                }}
                size="small"
                color="primary"
                className={classes.peopleButton}
                href={`mailto:${email}`}
                title="Mail"
              >
                <EmailIcon className={classes.peopleIcon} />
              </Button>

              <Button
                size="small"
                color="primary"
                classes={{
                  root: classes.iconButton
                }}
                href={teamsUrl}
                onClick={() => removeQueryParam(navigateFunction, 'cntx')}
                target="_blank"
                rel="noreferrer"
                title={intl.formatMessage({
                  id: 'start_teams_chat',
                  defaultMessage: 'Start a teams chat'
                })}
              >
                <ChatIcon className={classes.peopleIcon} />
              </Button>
              <Button
                size="small"
                color="primary"
                classes={{
                  root: classes.iconButtonSmall
                }}
                href={teamsCallUrl}
                onClick={() => removeQueryParam(navigateFunction, 'cntx')}
                target="_blank"
                rel="noreferrer"
                title={intl.formatMessage({
                  id: 'start_teams_call',
                  defaultMessage: 'Start a teams call'
                })}
              >
                <div className={classes.iconTeamsCallWrapper}>
                  <img
                    src={TeamsImage}
                    alt="MicroSoft Teams logo"
                    width="20px"
                    className={classes.peopleTeams}
                  />
                  <CallIcon className={classes.iconTeamsCallOverlay} />
                </div>
              </Button>
            </>
          )}
          {phoneUrl && (
            <Button
              size="small"
              color="primary"
              classes={{
                root: classes.iconButtonSmall
              }}
              href={phoneUrl}
              onClick={() => removeQueryParam(navigateFunction, 'cntx')}
              target="_blank"
              title={intl.formatMessage({
                id: 'officelocationwidget_phone',
                defaultMessage: 'Phone'
              })}
            >
              <CallIcon className={classes.peopleIcon} />
            </Button>
          )}
          {mobilePhoneUrl && (
            <Button
              size="small"
              color="primary"
              classes={{
                root: classes.iconButton
              }}
              href={mobilePhoneUrl}
              onClick={() => removeQueryParam(navigateFunction, 'cntx')}
              target="_blank"
              title={intl.formatMessage({
                id: 'mobile_phone',
                defaultMessage: 'Mobile phone'
              })}
            >
              <PhoneAndroidIcon className={classes.peopleIcon} />
            </Button>
          )}
          {title && (
            <Button
              size="small"
              color="primary"
              classes={{
                root: classes.iconButton
              }}
              href={linkedInUrl}
              onClick={() => removeQueryParam(navigateFunction, 'cntx')}
              target="_blank"
              title={'LinkedIn'}
            >
              <img
                src={LinkedIn}
                alt="LinkedIn logo"
                width="20px"
                className={classes.peopleLinkedIn}
              />
            </Button>
          )}
        </CardActions>
      </Box>

      {showMobileUsLinkMessage && (
        <FindNotification
          open={showMobileUsLinkMessage}
          onClose={() => setShowMobileUsLinkMessage(false)}
          translationKey={'snackbar_mobile_us_portals_unavailable'}
          defaultMessage={`Sorry, US content is not available on mobile.`}
          deviceSettings={deviceSettings}
        />
      )}
    </Card>
    // </LazyLoad>
  )
}

const mapStateToProps = (state: Store) => {
  return {
    aadInfo: AuthStore.selectors.getAADInfo(state)
  }
}

export default connect(mapStateToProps)(PeopleResult)
