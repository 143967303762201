import { Action, AnyAction } from 'redux'
import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { Store } from '..'
import ResultMetaDataStore from 'store/ResultMetaData'
import { IDeviceSetting } from 'utils/deviceSettings'
import {
  ActionMetaData,
  CustomAction,
  getActionMetaData
} from 'store/extension/customAction'
import { IChatRequest } from 'components/models/Chat'
import Selectors from './selectors'
import * as Config from 'config'
import { FetchWithCache } from 'utils/api'
import AuthStore from 'store/Auth'
import { renewAuthorizationToken } from 'utils/token'
import { KPMGFindGlobalVariables } from 'store/KPMGFindGlobalVariables'
import { IUserSetting } from 'utils/userSettings'

export enum ResultsChatActionTypes {
  FETCH_REQUEST = 'resultChat/FETCH_REQUEST',
  FETCH_SUCCESS = 'resultChat/FETCH_SUCCESS',
  FETCH_FAILURE = 'resultChat/FETCH_FAILURE',
  NEW_TOPIC = 'resultChat/NEW_TOPIC',
  INITIALIZE_RESULTS_CHAT = 'persist/REHYDRATE'
}

export interface IFetchRequest extends CustomAction<ResultsChatActionTypes> {
  payload: {
    chatRequest: IChatRequest
  }
}
export type IFetchFailure = CustomAction<ResultsChatActionTypes>
export interface IFetchSuccess extends CustomAction<ResultsChatActionTypes> {
  payload: {
    chatRequest: IChatRequest
  }
}

export const fetchRequest = (
  chatRequest: IChatRequest,
  actionMetaData: ActionMetaData
): IFetchRequest => ({
  type: ResultsChatActionTypes.FETCH_REQUEST,
  payload: { chatRequest },
  metaData: {
    ...actionMetaData,
    isStartAction: true
  }
})
export const fetchSuccess = (
  chatRequest: IChatRequest,
  actionMetaData: ActionMetaData
): IFetchSuccess => ({
  type: ResultsChatActionTypes.FETCH_SUCCESS,
  payload: { chatRequest },
  metaData: actionMetaData
})
export const fetchFailure = (
  actionMetaData: ActionMetaData
): IFetchFailure => ({
  type: ResultsChatActionTypes.FETCH_FAILURE,
  metaData: actionMetaData
})

export type INewTopic = Action<ResultsChatActionTypes>

//TODO
export const newTopic = (): INewTopic => ({
  type: ResultsChatActionTypes.NEW_TOPIC
})

export const fetchResultsChat = (
  searchQuery: string,
  filterDatasource: string[] | string,
  searchType: string,
  userSettings: IUserSetting,
  deviceSettings: IDeviceSetting
  // eslint-disable-next-line @typescript-eslint/ban-types
): ThunkAction<Promise<void>, Store, {}, AnyAction> => {
  return async (
    // eslint-disable-next-line @typescript-eslint/ban-types
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    getState: () => Store
  ) => {
    const actionMetaData = getActionMetaData('chat')
    let requestIndex = 0
    const chatRequestList = Selectors.getChatRequests(getState())
    if (chatRequestList) {
      requestIndex = chatRequestList.length
    }

    var fetchChatRequest: IChatRequest = {
      RequestIndex: requestIndex,
      Request: searchQuery,
      Answer: {
        AnswerResponse: null,
        RelatedQuestions: [],
        DataSources: []
      },
      Language: userSettings.Language,
      Country: userSettings.Country,
      Function: userSettings.Function,
      ChatHistory: ''
    }

    dispatch(fetchRequest(fetchChatRequest, actionMetaData))
    dispatch(ResultMetaDataStore.actions.fetchRequest('chat', actionMetaData))

    try {
      if (!searchQuery || searchQuery === '' || searchQuery === '""') {
        dispatch(fetchFailure(actionMetaData))
        return
      }
      const executionTime = 0
      const totalRowCount = 0
      let chatRequest: IChatRequest | null = null

      // Get and check authentication token
      const aadInfo = AuthStore.selectors.getAADInfo(getState())
      const esToken = await renewAuthorizationToken(
        aadInfo.accessToken,
        aadInfo.instance,
        aadInfo.accounts
      )
      if (esToken !== aadInfo.accessToken) {
        AuthStore.actions.setAuthToken(esToken)
      }
      if (esToken === '') {
        throw new Error(
          'Authentication: Cannot renew Chat Enterprise Search authentication token'
        )
      }

      const apiUrl = `${Config.APIM_BASE_URL}chatapi/postorchestrator`

      chatRequestList.push(fetchChatRequest)

      const hasError = Selectors.getHasError(getState())

      // Clean previous answers from chat requestst list
      const chatRequestListCopy: IChatRequest[] = []
      chatRequestList.forEach((val) =>
        chatRequestListCopy.push(Object.assign({}, val))
      )
      for (let i = 0; i < chatRequestListCopy.length; i++) {
        chatRequestListCopy[i].Answer = {
          AnswerResponse: null,
          RelatedQuestions: [],
          DataSources: []
        }
      }

      const response = await FetchWithCache(
        apiUrl,
        {
          method: 'POST',
          headers: {
            'Ocp-Apim-Subscription-Key': `${Config.OCP_APIM_SUBSCRIPTION_KEY}`,
            'content-type': 'application/json',
            Authorization: `Bearer ${esToken}`
          },
          body: JSON.stringify(chatRequestListCopy)
        },
        hasError,
        true,
        actionMetaData.transactionType,
        KPMGFindGlobalVariables.getCurrentTab() === 'chat',
        searchQuery
      )
      if (!response || response.hasError || !response.responseJSON) {
        let hasChatError = false
        if (
          response.errorResponse &&
          (response.errorResponse.responseCode === 400 ||
            response.errorResponse.responseCode === 500) &&
          !response.errorResponse.internalError
        ) {
          hasChatError = true
        }

        dispatch(
          ResultMetaDataStore.actions.hasError(
            'chat',
            response.errorResponse,
            undefined,
            actionMetaData,
            hasChatError
          )
        )
        dispatch(fetchFailure(actionMetaData))
        return
      } else {
        chatRequest = response.responseJSON as IChatRequest
      }

      if (chatRequest) {
        dispatch(fetchSuccess(chatRequest, actionMetaData))

        dispatch(
          ResultMetaDataStore.actions.fetchSuccess(
            'chat',
            {
              executionTime: executionTime,
              resultsCount: totalRowCount
            },
            actionMetaData
          )
        )
      }
    } catch (error) {
      dispatch(
        ResultMetaDataStore.actions.hasError(
          'chat',
          undefined,
          error,
          actionMetaData
        )
      )
      dispatch(fetchFailure(actionMetaData))
    }
  }
}

export type ResultsChatActions = IFetchRequest | IFetchSuccess | IFetchFailure
