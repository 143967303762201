import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Typography, Link, Box, Grid, useMediaQuery } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { externalLinkClicked } from 'utils/tracking'
import highlightedText, { prepareHighlightWords } from 'utils/highlightText'
import { generateUrlBreadcrumbs } from 'utils/string'
import ResultsStore from 'store/Results'
import { getStylesStatsResult } from 'styles/results/StatsResult'
import { IDeviceSetting } from 'utils/deviceSettings'
import { getCurrentScope } from 'utils/queryParams'
import { Theme } from '@mui/material'
import TooltipTitle from '../common/TooltipTitle'
import StatisticsPlaceHolder from 'images/kpmg_placeholder.png'
import { CustomDescription } from '../common/CustomDescription'

export interface StatsResultProps {
  title: string
  body: string
  url: string
  image: string
  sources?: string
  index?: any
  searchQuery?: string
  breadcrumbContent?: string
  premium?: boolean
  deviceSettings: IDeviceSetting
}

export function StatsResult(props: StatsResultProps): JSX.Element {
  const {
    title,
    body,
    url,
    image,
    index,
    sources,
    searchQuery,
    breadcrumbContent,
    deviceSettings
  } = props
  const classes = getStylesStatsResult()
  const scope = getCurrentScope(false)
  const dispatch = useDispatch()

  const [isVisited, setVisited] = React.useState(
    useSelector((state: any) =>
      ResultsStore.selectors.isResultVisited(state, url)
    )
  )

  const previewPicStyles = makeStyles((theme: Theme) => ({
    previewPic: {
      backgroundImage: image
        ? `url("${image}"),url("${StatisticsPlaceHolder}")`
        : `url("${StatisticsPlaceHolder}")`,
      backgroundColor: '#7787a1',
      backgroundPosition: 'top'
    }
  }))
  const previewPicClasses = previewPicStyles()

  const setIsVisited = () => {
    if (!isVisited) {
      dispatch(ResultsStore.actions.storeResultHistory(url))
      setVisited(true)
    }
  }

  let urlBreadcrumbs = ''

  breadcrumbContent
    ? (urlBreadcrumbs = breadcrumbContent)
    : (urlBreadcrumbs = generateUrlBreadcrumbs(scope, url, false, sources))

  return (
    <Grid container className={classes.statsWholeResult}>
      {!deviceSettings.renderMobile && (
        <Grid item xs={3} className={classes.statsPreviewPicWrapper}>
          {props.premium && (
            <div className={classes.premiumFlare}>
              <div className={classes.premiumFlareIcon}>+</div>
            </div>
          )}
          <div
            className={`${classes.statsPreviewPic} ${previewPicClasses.previewPic} `}
            role="img"
            aria-label={title ? title : ''}
          />
        </Grid>
      )}
      <Grid
        item
        {...(!deviceSettings.renderMobile ? { xs: 9 } : {})}
        className={classes.statsGridItem}
      >
        <Box className={classes.result} style={{ marginBottom: 0 }}>
          {deviceSettings.renderMobile && props.premium && (
            <div className={classes.premiumFlareInline}>
              <div className={classes.premiumFlareIconInline}>+</div>
            </div>
          )}
          {urlBreadcrumbs.length > 0 && (
            <Typography
              variant="body1"
              display="inline"
              className={classes.urlBreadcrumbs}
            >
              {urlBreadcrumbs}
            </Typography>
          )}
          <Link
            href={url}
            onClick={() => {
              setIsVisited()
              externalLinkClicked({ title, url, index })
            }}
            className={`${classes.link}`}
            {...(!deviceSettings.isMobile
              ? {
                  onAuxClick: (event: any) => {
                    setIsVisited()
                    externalLinkClicked({
                      title,
                      url,
                      index,
                      wasRightClicked: event?.button === 2 ? true : false
                    })
                  }
                }
              : {})}
            {...(deviceSettings.openLinksInNewTab
              ? { target: '_blank', rel: 'noreferrer' }
              : {})}
          >
            <Typography
              variant="h5"
              noWrap
              color="primary"
              className={`${classes.title} ${
                isVisited ? classes.isVisitedResult : ''
              }`}
              component="div"
            >
              <TooltipTitle title={title ? title : ''} singleLine={true} />
            </Typography>
          </Link>
          <Typography
            variant="body2"
            color="secondary"
            className={`${classes.body} txt-resultdescription-01`}
            component="div"
          >
            <CustomDescription
              descriptionValue={
                body
                  ? highlightedText(
                      body.replace(/(\r\n|\n|\r)/gm, ' '),
                      prepareHighlightWords(
                        searchQuery,
                        body.replace(/(\r\n|\n|\r)/gm, ' '),
                        []
                      ),
                      true
                    )
                  : ''
              }
            />
          </Typography>
        </Box>
      </Grid>
    </Grid>
  )
}
