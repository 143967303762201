import * as React from 'react'
import dayjs from 'dayjs'
import { ThumbnailImageProps } from 'react-grid-gallery'
import { getFileNameFromUrl } from 'utils/resultFunctions'
import TooltipTitle from '../common/TooltipTitle'
import { getStylesImageResultGallery } from 'styles/contents/resultpages/ImageResultGallery'
import { useIntl } from 'react-intl'
import { timeSince } from 'utils/date'
import { useState } from 'react'
import DownloadIcon from '@mui/icons-material/Download'
import { downloadSPOBlob } from 'utils/o365'
import CircularProgress from '@mui/material/CircularProgress'
import { connect } from 'react-redux'
import { Store } from 'store'
import AuthStore from 'store/Auth'

type AllProps = ReturnType<typeof mapStateToProps> & ThumbnailImageProps

const ImageResultGallery: React.FunctionComponent<AllProps> = (props) => {
  const [downloadInProgress, setDownloadInProgress] = useState(false)
  const intl = useIntl()
  const { src, style } = props.imageProps
  const { Write, Path } = props.item as any

  const styles = getStylesImageResultGallery()
  const title = getFileNameFromUrl(Path)

  const handleDownload = (e: any) => {
    e.stopPropagation()
    e.preventDefault()
    downloadSPOBlob(props.aadInfo, Path, '', title, setDownloadInProgress)
  }

  const uploaded = intl
    .formatMessage({
      id: 'datasourceimage_uploaded_ago',
      defaultMessage: 'uploaded {timesince} ago'
    })
    .replace(
      '{timesince}',
      Write ? timeSince(dayjs(Write).format('MM/DD/YYYY'), intl) : ''
    )

  const footerHeight = 52
  const paddingImage = 10

  return (
    <div
      className={styles.galleryTile}
      style={{
        height: (style.height as any) - 4,
        width: props.item.viewportWidth - 4,
        borderRadius: '4px'
      }}
    >
      <div
        style={{
          height: (style.height as any) - paddingImage,
          width: (style.width as any) - paddingImage
        }}
      >
        <div
          style={{
            border: paddingImage + 'px solid #fff',
            height: (style.height as any) - (paddingImage * 2 + 2 + 2),
            width: props.item.viewportWidth - (paddingImage * 2 + 2 + 2),
            overflow: 'hidden',
            borderRadius: '4px'
          }}
        >
          <div
            style={{
              height:
                (style.height as any) - (paddingImage * 2 + 2 + footerHeight),
              overflow: 'hidden'
            }}
          >
            <img
              alt={decodeURIComponent(title) || ''}
              src={src}
              title={decodeURIComponent(title) || ''}
              style={{
                ...style,
                height: (style.height as any) - paddingImage * 2 + 2,
                width: (style.width as any) - paddingImage * 2 + 2,
                marginTop: '-' + footerHeight / 2 + 'px'
              }}
              className={styles.img}
            />
          </div>
          <div style={{ height: footerHeight + 'px', backgroundColor: '#fff' }}>
            <div className={styles.title}>
              <TooltipTitle
                title={title ? decodeURIComponent(title) : ''}
                singleLine={true}
                useCssOnly={true}
              />
            </div>
            <div className={styles.footerTable}>
              <div className={styles.footerTableRow}>
                <div className={styles.footerTableCell}>
                  {!downloadInProgress && (
                    <span
                      tabIndex={0}
                      onClick={handleDownload}
                      onKeyDown={(event) => {
                        if (event.key === 'Enter') {
                          handleDownload(event)
                        }
                      }}
                    >
                      <DownloadIcon className={styles.downloadIcon} />
                    </span>
                  )}
                  {downloadInProgress && (
                    <CircularProgress
                      className={styles.loadingSpinner}
                      size={14}
                    />
                  )}
                </div>
                <div className={styles.footerTableCell}>
                  <span className={styles.subtitleWrapper}>
                    <TooltipTitle
                      title={Write ? uploaded : ''}
                      singleLine={true}
                      useCssOnly={true}
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state: Store) => {
  return {
    aadInfo: AuthStore.selectors.getAADInfo(state)
  }
}

export default connect(mapStateToProps)(ImageResultGallery)
