import React, { useState } from 'react'
import { Box } from '@mui/material'
import { getStylesLeftWidget } from 'styles/contents/resultpages/widgets/LeftWidget'
import { LeftArrow, RightArrow } from 'components/contents/common/Arrows'
import Slider from 'react-slick'
import 'styles/slick_carousel/slick-theme.css'
import 'styles/slick_carousel/slick.css'
import { connect } from 'react-redux'
import { Store } from 'store'
import SettingsStore from 'store/Settings'

export interface LeftWidgetSliderProps {
  slides: JSX.Element[]
  resultsLength: number
}

type AllProps = ReturnType<typeof mapStateToProps> & LeftWidgetSliderProps

function LeftWidgetSlider(props: AllProps): JSX.Element {
  const { slides, resultsLength, deviceSettings } = props

  const [slideIndex, setSlideIndex] = useState(0)
  const [slideTabIndex, setSlideTabIndex] = useState(0)

  const leftWidgetClasses = getStylesLeftWidget()

  const settings = {
    accessibility: true,
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    fade: false,
    dragable: true,
    touchMove: true,
    nextArrow:
      slideIndex + 3 >= resultsLength ||
      slideTabIndex + 1 > resultsLength ||
      deviceSettings.renderMobile ? (
        <></>
      ) : (
        <RightArrow />
      ),
    prevArrow:
      (slideIndex === 0 && slideTabIndex === 0) ||
      deviceSettings.renderMobile ? (
        <></>
      ) : (
        <LeftArrow />
      ),
    afterChange: (newIndex: any) => {
      setSlideIndex(newIndex)
    }
  }

  return (
    <>
      {!deviceSettings.renderMobile && slides.length > 0 && (
        <div
          className={leftWidgetClasses.cardContainer}
          onKeyUpCapture={(event) => {
            const target = event.target as HTMLElement
            if (
              target.parentNode &&
              target.parentNode.children &&
              target.parentNode.children.length > 0 &&
              target.parentNode.children[0].classList &&
              target.parentNode.children[0].classList.contains('MuiBox-root')
            ) {
              if (event.key === 'Tab' && event.shiftKey) {
                if (slideTabIndex - 1 > -1) {
                  setSlideTabIndex(slideTabIndex - 1)
                }
              } else if (event.key === 'Tab') {
                if (slideTabIndex < resultsLength) {
                  setSlideTabIndex(slideTabIndex + 1)
                }
              }
            }
          }}
        >
          <Slider className={leftWidgetClasses.slider} {...settings}>
            {slides}
          </Slider>
        </div>
      )}
      {deviceSettings.renderMobile && slides.length > 0 && (
        <Box className={leftWidgetClasses.mobileScrollContainer}>{slides}</Box>
      )}
    </>
  )
}

const mapStateToProps = (state: Store) => {
  return {
    deviceSettings: SettingsStore.selectors.getDeviceSettings(state)
  }
}

export default connect(mapStateToProps)(LeftWidgetSlider)
