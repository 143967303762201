import * as React from 'react'
import { IAADState } from 'store/Auth/reducers'
import { getSummary, pollSummaryJobStatus } from 'utils/languageApi'
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  ListItemIcon,
  ListItemText,
  Slider,
  Typography
} from '@mui/material'
import { useIntl } from 'react-intl'
import TranslateIcon from '@mui/icons-material/Translate'
import { getStylesTranslationDialog } from 'styles/contents/common/TranslationDialog'
import { useState } from 'react'
import {
  IResultSummaryRequestResponse,
  ISummaryJobStatusResponse,
  ISummaryResult
} from 'components/models/Summary'

interface ISummaryDialogProps {
  isOpen: boolean
  closeDialog: (state: boolean) => void
  aadInfo: IAADState
  listItemID?: string
  siteID?: string
  listID?: string
  webID?: string
  summaryResult: ISummaryResult
  setSummmaryResult: (result: ISummaryResult) => void
}

export default function SummaryDialog(props: ISummaryDialogProps) {
  const {
    isOpen,
    closeDialog,
    aadInfo,
    listItemID,
    siteID,
    listID,
    webID,
    summaryResult,
    setSummmaryResult
  } = props

  const intl = useIntl()
  const classes = getStylesTranslationDialog()
  const [summaryRunning, setSummaryRunning] = useState(false)
  const [sentenceCount, setSentenceCount] = useState(6)
  const [hasError, setHasError] = useState(!(listItemID && siteID && listID))

  const handleSummaryCall = () => {
    setSummaryRunning(true)

    getSummary(
      listItemID ? listItemID : '',
      siteID ? siteID : '',
      listID ? listID : '',
      webID ? webID : '',
      aadInfo,
      sentenceCount
    ).then((result: IResultSummaryRequestResponse) => {
      if (result.hasError) {
        setHasError(true)
        setSummaryRunning(false)
      } else {
        pollSummaryJobStatus(result, aadInfo, 10000).then(
          (pollingResult: ISummaryJobStatusResponse) => {
            setSummaryRunning(false)
            if (pollingResult.status !== 'success' || !pollingResult.summary) {
              setHasError(true)
            } else {
              setSummmaryResult({
                hasBeenFetched: true,
                summary: pollingResult.summary
              })
            }
          }
        )
      }
    })
  }

  const handleSliderChange = (event: Event, newValue: number | number[]) => {
    setSentenceCount(newValue as number)
  }

  return (
    <Dialog
      onClose={() => closeDialog(false)}
      open={isOpen}
      fullWidth
      maxWidth="sm"
    >
      <DialogContent>
        <Typography
          variant="h5"
          color="primary"
          component="div"
          className={classes.headline}
        >
          {intl.formatMessage({
            id: 'summary_dialog_title',
            defaultMessage: 'Summarization of a document'
          })}
        </Typography>
        {!hasError && (
          <div>
            {!summaryResult.hasBeenFetched && (
              <Grid
                container
                direction="row"
                alignItems="center"
                className={classes.listItem}
              >
                <Grid item xs={2} sm={1}>
                  <ListItemIcon className={classes.iconContainer}>
                    <TranslateIcon className={classes.iconItem} />
                  </ListItemIcon>
                </Grid>
                <Grid item xs={5} sm={6}>
                  <ListItemText
                    primary={intl.formatMessage({
                      id: 'summary_dialog_label',
                      defaultMessage: 'Select sentence count'
                    })}
                  />
                </Grid>
                <Grid item xs={5} sm={5}>
                  <Slider
                    defaultValue={6}
                    step={1}
                    marks
                    min={1}
                    max={20}
                    onChange={handleSliderChange}
                  />
                </Grid>
              </Grid>
            )}
            {summaryResult.hasBeenFetched && (
              <div className={classes.listItem}>{summaryResult.summary}</div>
            )}
          </div>
        )}
        {hasError && (
          <div className={classes.listItem}>
            {intl.formatMessage({
              id: 'summary_dialog_error',
              defaultMessage: 'Error requesting the summary for the document'
            })}
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Box
          justifyContent="space-evenly"
          display="flex"
          paddingBottom="10px"
          marginRight="16px"
        >
          <Button
            color="primary"
            size="small"
            onClick={() => closeDialog(false)}
            disabled={summaryRunning}
          >
            {summaryResult.hasBeenFetched
              ? intl.formatMessage({
                  id: 'close',
                  defaultMessage: 'Close'
                })
              : intl.formatMessage({
                  id: 'cancel',
                  defaultMessage: 'Cancel'
                })}
          </Button>
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={() => handleSummaryCall()}
            style={{ marginLeft: '10px' }}
            disabled={
              !(listID && listItemID && siteID) || summaryResult.hasBeenFetched
            }
          >
            <div>
              {intl.formatMessage({
                id: 'context_menu_summary',
                defaultMessage: 'Summarize'
              })}
            </div>
            {summaryRunning && (
              <CircularProgress className={classes.loadingSpinner} size={15} />
            )}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  )
}
