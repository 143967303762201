import React, { useEffect, useState } from 'react'
import { Box, Grid, Typography, Link, CircularProgress } from '@mui/material'
import { externalLinkClicked } from 'utils/tracking'
import { FormattedMessage, useIntl } from 'react-intl'
import { dateFormatOptions } from 'constants/constants'
import { IDeviceSetting } from 'utils/deviceSettings'
import { getStylesTeamResult } from 'styles/results/TeamResult'
import { stripHtml } from 'utils/string'
import highlightedText, { prepareHighlightWords } from 'utils/highlightText'
import {
  ChatType,
  getChannelInformations,
  IChatInformations
} from 'utils/teams'
import PeoplePlaceholder from 'images/people_placeholder.png'
import { getProfilePicture } from 'utils/msgraphProfilePicture'
import { makeStyles } from '@mui/styles'
import { ISynonymsApplied } from 'components/models/SynonymsApplied'
import { IAADState } from 'store/Auth/reducers'

export interface TeamResultProps {
  lastModifiedDateTime?: string
  description?: string
  key: string | number
  index: number
  deviceSettings: IDeviceSetting
  searchQuery: string
  from: string
  upn: string
  channelId: string
  teamId: string
  chatId: string
  aadInfo: IAADState
  blockChatInformationFetch: boolean
  messageId: string
  synonymsApplied: ISynonymsApplied[]
}

export function TeamResult(props: TeamResultProps): JSX.Element {
  const {
    lastModifiedDateTime,
    description,
    deviceSettings,
    searchQuery,
    from,
    upn,
    channelId,
    teamId,
    chatId,
    aadInfo,
    blockChatInformationFetch,
    messageId,
    index,
    synonymsApplied
  } = props

  const [channelInfo, setChannelInfo] = useState<IChatInformations>({
    team: null,
    channel: null,
    chat: null,
    isValid: false,
    fetched: false
  })
  const [pictureUrl, setPictureUrl] = React.useState('')

  const classes = getStylesTeamResult()
  const intl = useIntl()

  const loadTeamChannel = async () => {
    if (!blockChatInformationFetch) {
      const channelResult = await getChannelInformations(
        chatId,
        channelId,
        teamId,
        aadInfo,
        deviceSettings
      )

      setChannelInfo(channelResult)
    }
  }

  const userProfileStyles = makeStyles(() => ({
    peopleProfilepic: {
      width: '72px',
      height: '72px',
      borderRadius: '50%',
      backgroundImage: `url('${
        pictureUrl
          ? pictureUrl.replace('MThumb', 'LThumb').split(' ').join('%20')
          : ''
      }'),url('${PeoplePlaceholder}') !important`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center'
    },
    defaultProfilepic: {
      backgroundImage: 'url(' + PeoplePlaceholder + ')'
    }
  }))
  const userProfileClasses = userProfileStyles()

  const loadProfilePic = async () => {
    const pictureUrl = await getProfilePicture(
      upn,
      null,
      deviceSettings,
      aadInfo
    )
    setPictureUrl(pictureUrl)
  }

  useEffect(() => {
    loadTeamChannel()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blockChatInformationFetch])

  useEffect(() => {
    loadProfilePic()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getTitleText = () => {
    return `${from}`
  }

  const getBreadCrumbText = (textToShow: string): JSX.Element => {
    return (
      <Typography variant="body1" className={classes.urlBreadcrumbs}>
        {`${textToShow} - ${
          lastModifiedDateTime
            ? intl.formatDate(
                lastModifiedDateTime.replace('Z', ''),
                dateFormatOptions
              )
            : ''
        }`}
      </Typography>
    )
  }

  const getBreadCrumb = (): JSX.Element => {
    if (channelInfo.fetched) {
      if (channelInfo.chat) {
        switch (channelInfo.chat.chatType) {
          case ChatType.MEETING:
            return getBreadCrumbText(channelInfo.chat.topic)
          default:
            return getBreadCrumbText(
              intl.formatMessage({
                id: 'breadcrumb_teamresult_oneononemessage',
                defaultMessage: 'In a chat with you'
              })
            )
        }
      } else if (channelInfo.channel) {
        const text = `${
          channelInfo.team && channelInfo.team.title
            ? `${channelInfo.team.title} › `
            : ''
        }${channelInfo.channel.title}`
        return getBreadCrumbText(text)
      }
      return getBreadCrumbText(
        intl.formatMessage({
          id: 'breadcrumb_teamresult_failedmessage',
          defaultMessage: "Couldn't load chat details"
        })
      )
    } else {
      return (
        <div className={classes.loadingContainer}>
          <CircularProgress className={classes.loadingSpinner} size={10} />
          <Typography
            variant="body1"
            className={[
              classes.urlBreadcrumbs,
              classes.urlBreadcrumbsLoading
            ].join(' ')}
          >
            {intl.formatMessage({
              id: 'breadcrumb_teamresult_loading',
              defaultMessage: 'Loading'
            })}
          </Typography>
        </div>
      )
    }
  }

  const getLinkUrl = () => {
    if (channelInfo.chat) return channelInfo.chat.url
    else if (channelInfo.channel && channelInfo.channel.url) {
      try {
        // generate the url to the message item inside the channel
        // So no additional request ist required to get the direct message url
        // - replace channel endpoint with message endpoint
        // - append the message id to the url
        // - parameters didn't get changed
        const urlparts = channelInfo.channel.url.split('?')
        if (urlparts.length !== 2 || !messageId) {
          return channelInfo.channel.url
        }

        const baseUrl = urlparts[0].substring(0, urlparts[0].lastIndexOf('/'))

        return `${baseUrl.replace('/channel/', '/message/')}/${messageId}?${
          urlparts[1]
        }`
      } catch {
        return channelInfo.channel.url
      }
    } else if (channelInfo.team) return channelInfo.team.url

    return ''
  }

  return (
    <Grid container className={classes.result}>
      <Grid>
        <div className={classes.profilepicWrapper}>
          <div
            className={
              userProfileClasses.peopleProfilepic +
              ' ' +
              userProfileClasses.defaultProfilepic
            }
            role="img"
            aria-label={getTitleText()}
          />
        </div>
      </Grid>
      <Grid item xs className={classes.contentContainer}>
        <Box>
          <div className={classes.urlWrapper}>{getBreadCrumb()}</div>
          <Link
            underline={'hover'}
            className={classes.link}
            {...(deviceSettings.openLinksInNewTab === true
              ? { target: '_blank', rel: 'noreferrer' }
              : { target: '_blank' })}
            href={getLinkUrl()}
            onClick={(event: any) => {
              if (!channelInfo.fetched || !channelInfo.isValid) {
                event.preventDefault()
                return
              }
              externalLinkClicked({
                title: getTitleText(),
                url: getLinkUrl(),
                index: index
              })
            }}
            {...(!deviceSettings.isMobile
              ? {
                  onAuxClick: (event: any) => {
                    if (!channelInfo.fetched || !channelInfo.isValid) {
                      event.preventDefault()
                      return
                    }
                    externalLinkClicked({
                      title: getTitleText(),
                      url: getLinkUrl(),
                      index: index,
                      wasRightClicked: event?.button === 2 ? true : false
                    })
                  }
                }
              : {})}
          >
            <Typography
              variant="h5"
              noWrap
              color="primary"
              className={classes.title}
            >
              {getTitleText()}
            </Typography>
          </Link>
          <Typography
            variant="body2"
            color="secondary"
            gutterBottom
            className={`${classes.body} txt-resultdescription-01`}
            component="div"
          >
            {description ? (
              highlightedText(
                stripHtml(description),
                prepareHighlightWords(
                  searchQuery,
                  stripHtml(description),
                  synonymsApplied
                ),
                false
              )
            ) : (
              <FormattedMessage
                id="missing_description_text"
                defaultMessage="Description is not available"
              />
            )}
          </Typography>
        </Box>
      </Grid>
    </Grid>
  )
}
