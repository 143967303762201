import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { Box, Typography } from '@mui/material'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import Link from '@mui/material/Link'
import { trackLeftWidgetClick } from 'utils/tracking'
import { WidgetKey } from 'constants/widgets'
import { getStylesLeftWidget } from 'styles/contents/resultpages/widgets/LeftWidget'
import createDOMPurify from 'dompurify'
import TooltipTitle from 'components/contents/common/TooltipTitle'
import KlardenkerPlaceHolder from 'images/kpmg_placeholder.png'
import { IKlardenkerResult } from 'components/models/KlardenkerResult'
import { IDeviceSetting } from 'utils/deviceSettings'
import { getKlardenkerImage } from 'utils/klardenker'

export interface ILeftKlardenkerWidgetResultProps {
  item: IKlardenkerResult
  index: number
  deviceSettings: IDeviceSetting
  authToken: string
}

type AllProps = ILeftKlardenkerWidgetResultProps

function LeftKlardenkerWidgetResult(props: AllProps): JSX.Element {
  const { item, deviceSettings, index, authToken } = props

  const [imageUrlFallBack, setImageUrlFallBack] = useState(item.image)

  useEffect(() => {
    // Lazy load the image result
    if (!imageUrlFallBack && item.id) {
      getKlardenkerImage(item.id, authToken).then(
        (lazyLoadedImageUrl: string | null) => {
          if (lazyLoadedImageUrl) {
            setImageUrlFallBack(lazyLoadedImageUrl)
          }
        }
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const DOMPurify = createDOMPurify(window)
  const leftWidgetClasses = getStylesLeftWidget()
  const intl = useIntl()

  return (
    <Box key={'lk' + index}>
      <Box key={'lkb1' + index} style={{ width: 20 }} />
      <Link
        key={'lkl1' + index}
        onClick={() => {
          trackLeftWidgetClick({
            title: DOMPurify.sanitize(item.title),
            url: DOMPurify.sanitize(item.url),
            widgetKey: WidgetKey.klardenker,
            index: index
          })
        }}
        {...(!deviceSettings.isMobile
          ? {
              onAuxClick: (event: any) => {
                trackLeftWidgetClick({
                  title: DOMPurify.sanitize(item.title),
                  url: DOMPurify.sanitize(item.url),
                  widgetKey: WidgetKey.klardenker,
                  index: index,
                  wasRightClicked: event?.button === 2 ? true : false
                })
              }
            }
          : {})}
        href={item.url}
        className={leftWidgetClasses.cardLink}
        {...(deviceSettings.openLinksInNewTab === true
          ? { target: '_blank', rel: 'noreferrer' }
          : {})}
      >
        <Card key={'lkc' + index} className={leftWidgetClasses.card}>
          <CardMedia
            key={'lkcm' + index}
            className={leftWidgetClasses.media}
            component="div"
            role="img"
            aria-label={
              item.title &&
              imageUrlFallBack != null &&
              imageUrlFallBack.length > 0
                ? DOMPurify.sanitize(item.title?.trim())
                : ''
            }
            style={{
              backgroundImage:
                imageUrlFallBack != null && imageUrlFallBack.length > 0
                  ? `url("${DOMPurify.sanitize(
                      imageUrlFallBack
                    )}"), url("${DOMPurify.sanitize(imageUrlFallBack).replace(
                      '/355/',
                      '/754/'
                    )}"),url("${KlardenkerPlaceHolder}")`
                  : `url("${KlardenkerPlaceHolder}")`,
              backgroundColor: '#7787a1'
            }}
          ></CardMedia>
          <CardContent
            key={'lkcc' + index}
            style={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <Typography
              key={'lkt1' + index}
              variant="body1"
              color="primary"
              component="div"
              style={{
                minHeight: 48
              }}
            >
              <TooltipTitle
                title={DOMPurify.sanitize(item.title?.trim())}
                key={'lkd1' + index}
              />
            </Typography>
            <Box key={'lkb4' + index} className={leftWidgetClasses.spacer} />
            <Typography
              key={'lkt2' + index}
              variant="body2"
              color="textSecondary"
              component="div"
              style={{ marginTop: 10, fontStyle: 'italic' }}
            >
              {intl.formatMessage({ id: 'origin', defaultMessage: 'Source' })}:{' '}
              {intl.formatMessage({
                id: 'klardenker',
                defaultMessage: 'Klardenker'
              })}
            </Typography>
          </CardContent>
        </Card>
      </Link>
    </Box>
  )
}

export default LeftKlardenkerWidgetResult
