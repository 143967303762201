import {
  IResultTranslations,
  ITranslationResult
} from 'components/models/Translation'
import * as Config from 'config'
import { renewAuthorizationToken } from './token'
import { IAADState } from 'store/Auth/reducers'
import {
  IResultSummaryRequestResponse,
  ISummaryJobStatusResponse
} from 'components/models/Summary'

export async function getTranslations(
  resultTranslations: IResultTranslations,
  aadInfo: IAADState
): Promise<IResultTranslations> {
  try {
    const esToken = await renewAuthorizationToken(
      aadInfo.accessToken,
      aadInfo.instance,
      aadInfo.accounts
    )

    const languageAPIPostTextTranslationUrl = `${
      Config.APIM_BASE_URL
    }languageapi/posttexttranslation`

    const requestBody: {
      values: {
        text: string
      }[]
      to: string
    } = {
      values: resultTranslations.values,
      to: resultTranslations.to
    }

    if (requestBody.to === 'ja-JP') requestBody.to = 'ja'
    else if (requestBody.to === 'ko-KR') requestBody.to = 'ko'
    else if (requestBody.to === 'pt-BR') requestBody.to = 'pt'
    else if (requestBody.to === 'zh') requestBody.to = 'zh-Hans'

    const languageResponse = await fetch(languageAPIPostTextTranslationUrl, {
      method: 'POST',
      headers: {
        accept: 'application/json',
        'content-type': 'application/json',
        'Ocp-Apim-Subscription-Key': `${Config.OCP_APIM_SUBSCRIPTION_KEY}`,
        Authorization: `Bearer ${esToken}`
      },
      body: JSON.stringify(requestBody)
    })

    if (!languageResponse || !languageResponse.ok) {
      resultTranslations.hasError = true
      return resultTranslations
    }

    const responseJson: ITranslationResult[] = await languageResponse.json()

    if (responseJson.length <= 0) {
      resultTranslations.hasError = true
      return resultTranslations
    }

    responseJson.forEach(
      (translationResult: ITranslationResult, index: number) => {
        if (
          translationResult.translations.length > 0 &&
          index < resultTranslations.values.length
        ) {
          resultTranslations.values[index].translatedText =
            translationResult.translations[0].text
        }
      }
    )

    resultTranslations.hasError = false
    return resultTranslations
  } catch {
    resultTranslations.hasError = true
    return resultTranslations
  }
}

export async function getSummary(
  listItemID: string,
  siteID: string,
  listID: string,
  webID: string,
  aadInfo: IAADState,
  sentenceCount: number
): Promise<IResultSummaryRequestResponse> {
  try {
    if (!listItemID || !siteID || !listID) {
      return { hasError: true }
    }

    const esToken = await renewAuthorizationToken(
      aadInfo.accessToken,
      aadInfo.instance,
      aadInfo.accounts
    )

    const summaryAPIPostGetSummaryUrl = `${
      Config.APIM_BASE_URL
    }languageapi/postdocumentsummary`

    const requestBody: {
      siteId: string
      webId?: string
      listId: string
      itemId: string
      sentenceCount: number
    } = {
      siteId: siteID,
      listId: listID,
      itemId: listItemID,
      sentenceCount: sentenceCount
    }

    if (webID) {
      requestBody.webId = webID
    }

    const summaryResponse = await fetch(summaryAPIPostGetSummaryUrl, {
      method: 'POST',
      headers: {
        accept: 'application/json',
        'content-type': 'application/json',
        'Ocp-Apim-Subscription-Key': `${Config.OCP_APIM_SUBSCRIPTION_KEY}`,
        JWTToken: esToken
      },
      body: JSON.stringify(requestBody)
    })

    if (summaryResponse.status === 202 && summaryResponse.headers) {
      const opLocation = summaryResponse.headers.get('operation-location')

      if (opLocation) {
        return {
          operationLocation: opLocation,
          hasError: false
        }
      } else {
        return {
          hasError: true
        }
      }
    }

    return {
      hasError: true
    }
  } catch (e) {
    return {
      hasError: true
    }
  }
}

async function getSummaryJobStatus(
  summaryRequestResponse: IResultSummaryRequestResponse,
  aadInfo: IAADState
): Promise<ISummaryJobStatusResponse> {
  try {
    if (!summaryRequestResponse.operationLocation) {
      return {
        status: 'failed',
        errors: []
      }
    }

    const esToken = await renewAuthorizationToken(
      aadInfo.accessToken,
      aadInfo.instance,
      aadInfo.accounts
    )

    const summaryAPIPostGetSummaryStatusUrl = `${
      Config.APIM_BASE_URL
    }languageapi/postgetdocumentsummarystatus`

    const requestBody: {
      operationLocation: string
    } = {
      operationLocation: summaryRequestResponse.operationLocation
    }

    const summaryResponse = await fetch(summaryAPIPostGetSummaryStatusUrl, {
      method: 'POST',
      headers: {
        accept: 'application/json',
        'content-type': 'application/json',
        'Ocp-Apim-Subscription-Key': `${Config.OCP_APIM_SUBSCRIPTION_KEY}`,
        JWTToken: esToken
      },
      body: JSON.stringify(requestBody)
    })

    if (summaryResponse.ok) {
      const jsonResponse = await summaryResponse.json()

      return jsonResponse
    }

    return {
      status: 'failed',
      errors: []
    }
  } catch (e) {
    return {
      status: 'failed',
      errors: []
    }
  }
}

const wait = function (ms = 10000) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms)
  })
}

export async function pollSummaryJobStatus(
  summaryRequestResponse: IResultSummaryRequestResponse,
  aadInfo: IAADState,
  delay: number
): Promise<ISummaryJobStatusResponse> {
  await wait(delay)
  let result = await getSummaryJobStatus(summaryRequestResponse, aadInfo)
  while (result.status === 'running' || result.status === 'notStarted') {
    await wait(delay)
    result = await getSummaryJobStatus(summaryRequestResponse, aadInfo)
  }

  return result
}
