import React from 'react'
import { connect } from 'react-redux'
import { Store } from 'store'
import SettingsStore from 'store/Settings'
// import LazyLoad from 'react-lazyload'
import { Box, Typography } from '@mui/material'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import Link from '@mui/material/Link'
import { useNavigate } from 'react-router-dom'
import { useIntl } from 'react-intl'
import {
  trackWidgetShown,
  trackException,
  trackLeftWidgetClick
} from 'utils/tracking'
import { removeQueryParam } from 'utils/queryParams'
import { WidgetKey } from 'constants/widgets'
import 'styles/slick_carousel/slick-theme.css'
import 'styles/slick_carousel/slick.css'
import { getStylesLeftInfographicsWidget } from 'styles/contents/resultpages/widgets/LeftInfographicsWidget'
import { getStylesLeftWidget } from 'styles/contents/resultpages/widgets/LeftWidget'
import createDOMPurify from 'dompurify'
import TooltipTitle from 'components/contents/common/TooltipTitle'
import InfographicsPlaceHolder from 'images/kpmg_placeholder.png'
import { IInfographicsResult } from 'components/models/InfographicsResult'
import LeftWidgetSlider from './common/LeftWidgetSlider'

export interface LeftInfographicsWidgetProps {
  infographicResults: IInfographicsResult[]
  query: any
}

type AllProps = ReturnType<typeof mapStateToProps> & LeftInfographicsWidgetProps

function LeftInfographicsWidget(props: AllProps): JSX.Element {
  const {
    infographicResults,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    query,
    deviceSettings
  } = props

  const DOMPurify = createDOMPurify(window)

  const navigateFunction = useNavigate()
  const leftWidgetClasses = getStylesLeftWidget()
  const classes = getStylesLeftInfographicsWidget()

  const intl = useIntl()

  React.useEffect(() => {
    trackWidgetShown({
      widgetType: WidgetKey.infographics,
      widgetPane: 'left'
    })
  }, [])

  let slides: JSX.Element[] = []
  try {
    slides = infographicResults.map((item: IInfographicsResult, i: number) => (
      // <LazyLoad offset={150}>
      <Box key={'li' + i}>
        <Box key={'lib' + i} style={{ width: 20 }} />
        <Link
          key={'lil' + i}
          onClick={() => {
            removeQueryParam(navigateFunction, 'cntx')
            trackLeftWidgetClick({
              title: DOMPurify.sanitize(item.title),
              url: DOMPurify.sanitize(item.url),
              widgetKey: WidgetKey.infographics,
              index: i
            })
          }}
          {...(!deviceSettings.isMobile
            ? {
                onAuxClick: (event: any) => {
                  removeQueryParam(navigateFunction, 'cntx')
                  trackLeftWidgetClick({
                    title: DOMPurify.sanitize(item.title),
                    url: DOMPurify.sanitize(item.url),
                    widgetKey: WidgetKey.infographics,
                    index: i,
                    wasRightClicked: event?.button === 2 ? true : false
                  })
                }
              }
            : {})}
          href={DOMPurify.sanitize(item.url)}
          className={leftWidgetClasses.cardLink}
          {...(deviceSettings.openLinksInNewTab === true
            ? { target: '_blank', rel: 'noreferrer' }
            : {})}
        >
          <Card key={'lic' + i} className={leftWidgetClasses.card}>
            <CardMedia
              key={'licm' + i}
              className={leftWidgetClasses.media}
              component="div"
              role="img"
              aria-label={
                item.title && item.image != null && item.image.length > 0
                  ? DOMPurify.sanitize(item.title?.trim())
                  : ''
              }
              style={{
                backgroundImage:
                  item.image != null && item.image.length > 0
                    ? `url("${DOMPurify.sanitize(
                        item.image
                      )}"),url("${InfographicsPlaceHolder}")`
                    : `url("${InfographicsPlaceHolder}")`,
                backgroundColor: '#7787a1',
                backgroundPosition: 'top'
              }}
            ></CardMedia>
            <CardContent
              key={'licc' + i}
              style={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column'
              }}
            >
              <Typography
                key={'lit1' + i}
                variant="body1"
                color="primary"
                component="div"
              >
                <TooltipTitle
                  title={DOMPurify.sanitize(item.title?.trim())}
                  key={'lnd1' + i}
                />
              </Typography>
              <Box key={'lis3' + i} className={leftWidgetClasses.spacer} />
              <Typography
                key={'lit2' + i}
                variant="body2"
                color="textSecondary"
                component="div"
                style={{ marginTop: 10, fontStyle: 'italic' }}
              >
                {intl.formatMessage({ id: 'origin', defaultMessage: 'Source' })}
                :{' '}
                {intl.formatMessage({
                  id: 'statista',
                  defaultMessage: 'Statista'
                })}
              </Typography>
            </CardContent>
          </Card>
        </Link>
      </Box>
      // </LazyLoad>
    ))
  } catch (error) {
    trackException(
      'Error in parsing slides in ResultsInfographicsFrame.tsx',
      error
    )
  }

  if (deviceSettings.renderMobile) {
    slides.push(<Box key="mobile" style={{ flex: '0 0 10px' }} />)
  }

  return (
    <Box
      id={'con-widget-left-infographics'}
      className={leftWidgetClasses.container}
    >
      {infographicResults && infographicResults.length > 0 && (
        <Typography
          variant="h6"
          color="primary"
          component="p"
          className={classes.title}
          gutterBottom
        >
          {intl.formatMessage({
            id: 'infographics_on',
            defaultMessage: 'Related Infographics'
          })}
        </Typography>
      )}
      <Box>
        <LeftWidgetSlider
          slides={slides}
          resultsLength={infographicResults.length}
        />
      </Box>
    </Box>
  )
}

const mapStateToProps = (state: Store) => {
  return {
    deviceSettings: SettingsStore.selectors.getDeviceSettings(state)
  }
}

export default connect(mapStateToProps)(LeftInfographicsWidget)
