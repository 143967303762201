import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Box, Fade } from '@mui/material'
import { connect } from 'react-redux'
import ReactPlaceholder from 'react-placeholder'
import { useIntl } from 'react-intl'
import queryString from 'query-string'
import { Store } from 'store'
import AuthStore from 'store/Auth'
import UserSettingsStore from 'store/UserSettings'
import SettingsStore from 'store/Settings'
import ResultsOffice365Store from 'store/ResultsOffice365'
import ResultMetaDataStore from 'store/ResultMetaData'
import RightTopWidgetContainer from 'components/contents/resultpages/widgetscontainer/RightTopWidgetContainer'
import { trackException } from 'utils/tracking'
import Pagination from 'components/contents/Pagination'
import {
  ChatMessageSourceResponse,
  generateOffice365QueryParams,
  HitsResponse,
  IOffice365QueryParams
} from 'utils/o365'
import 'Placeholder.css'
import { useReactRouterQueryStringInterface } from 'utils/useQueryState'
import { authorizeResultPage } from 'utils/authorization'
import createDOMPurify from 'dompurify'
import { dataSourcesTabs } from 'constants/constants'
import SearchSuggestions from 'components/contents/common/SearchSuggestions'
import O365FilterBar from './O365FilterBar'
import { IUserSetting } from 'utils/userSettings'
import { IDeviceSetting } from 'utils/deviceSettings'
import FeaturedResults from '../FeaturedResults'
import LeftWidgetContainer from '../widgetscontainer/LeftWidgetContainer'
import { getStylesResults } from 'styles/contents/resultpages/Results'
import { getStylesResultsO365 } from 'styles/contents/resultpages/ResultsO365'
import { useFilter } from 'utils/filters'
import RightWidgetContainer from '../widgetscontainer/RightWidgetContainer'
import ResultTeamsMobile from './ResultTeamsMobile'
import { TeamResult } from 'components/contents/results/TeamResult'
import { KPMGFindGlobalVariables } from 'store/KPMGFindGlobalVariables'
import { IFindConfiguration } from 'store/Settings/reducers'
import { CognitiveMessage } from 'components/contents/common/CognitiveMessage'
import { useLocation } from 'react-router-dom'
import ErrorPage from 'components/contents/common/ErrorPage'

export interface ResultTeamsProps {
  datasources: any
  moreResultsAvailable: boolean
}

type AllProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  ResultTeamsProps

function ResultTeams(props: AllProps) {
  const {
    fetchResultsOffice365,
    extendedResults,
    results,
    resultsCount,
    hasResultsBeenFetched,
    hasErrorStore,
    userSettings,
    datasources,
    deviceSettings,
    aadInfo,
    moreResultsAvailable,
    findConfiguration,
    setUserSettings,
    useCognitiveSearch
  } = props
  const DOMPurify = createDOMPurify(window)
  const resultClasses = getStylesResults()
  const o365Classes = getStylesResultsO365()

  const [currentPage] = useFilter('page', '1')
  const [searchQuery] = useFilter('q')
  const location = useLocation()

  // used to prevent feature result rendering before the fetch
  const [firstRender, setFirstRender] = useState(true)

  const [resultsFetched, setResultsFetched] = useState(false)
  const { getQueryString } = useReactRouterQueryStringInterface()

  const intl = useIntl()

  const queryStringFromUrl = getQueryString()

  const [cognitiveSearchEnabled, setCognitiveSearchEnabled] =
    useState(useCognitiveSearch)
  const [showCognitiveMessage, setShowCognitiveMessage] = useState(
    userSettings.ShowCognitiveMessage
  )

  useEffect(() => {
    KPMGFindGlobalVariables.setCurrentTab(dataSourcesTabs.o365teams)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(
    () => {
      setCognitiveSearchEnabled(useCognitiveSearch)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [useCognitiveSearch]
  )

  useEffect(
    () => {
      setShowCognitiveMessage(userSettings.ShowCognitiveMessage)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [userSettings.ShowCognitiveMessage]
  )

  const setup = useCallback(
    async (searchQuery: string, currPage: number) => {
      authorizeResultPage(
        'Office365',
        userSettings,
        deviceSettings,
        DOMPurify.sanitize(location && location.search ? location.search : '')
      )

      const queryParams =
        queryString.parse(DOMPurify.sanitize(queryStringFromUrl)) || {}

      const office365Params: IOffice365QueryParams = {
        searchQuery: searchQuery,
        page: currPage
      }

      generateOffice365QueryParams(queryParams, office365Params)

      fetchResultsOffice365(
        office365Params.searchQuery,
        office365Params.page,
        deviceSettings,
        userSettings,
        queryParams,
        findConfiguration
      ).then(() => {
        if (firstRender) {
          setFirstRender(false)
        }
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [queryStringFromUrl]
  )

  useEffect(() => {
    if (hasResultsBeenFetched) setResultsFetched(hasResultsBeenFetched)
  }, [hasResultsBeenFetched])

  useEffect(() => {
    try {
      setup(
        DOMPurify.sanitize(searchQuery),
        Number(DOMPurify.sanitize(currentPage))
      )
    } catch (error) {
      trackException('Error in fetching results in ResultsOffice365.tsx', error)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setup, intl])

  const returnAllItems = (type: string) => {
    if (!results || results.length < 1) {
      return []
    }

    const items = []

    try {
      const firstResults = results.length < 4 ? results.length : 3

      if (type === 'first') {
        for (let i = 0; i < firstResults; i++) {
          const teamItem = results[i] as HitsResponse<ChatMessageSourceResponse>
          items.push(
            <TeamResult
              lastModifiedDateTime={DOMPurify.sanitize(
                teamItem.resource.lastModifiedDateTime
              )}
              description={DOMPurify.sanitize(teamItem.summary)}
              key={i}
              index={i}
              deviceSettings={deviceSettings}
              searchQuery={DOMPurify.sanitize(searchQuery)}
              from={DOMPurify.sanitize(
                teamItem.resource.from.emailAddress.name
              )}
              upn={DOMPurify.sanitize(
                teamItem.resource.from.emailAddress.address
              )}
              channelId={
                teamItem.resource.channelIdentity
                  ? teamItem.resource.channelIdentity.channelId
                  : ''
              }
              teamId={
                teamItem.resource.channelIdentity
                  ? teamItem.resource.channelIdentity.teamId
                  : ''
              }
              chatId={teamItem.resource.chatId}
              aadInfo={aadInfo}
              blockChatInformationFetch={!resultsFetched}
              messageId={teamItem.resource.id ? teamItem.resource.id : ''}
              synonymsApplied={extendedResults.synonymsApplied}
            />
          )
        }
      } else {
        for (let i = firstResults; i < results.length; i++) {
          const teamItem = results[i] as HitsResponse<ChatMessageSourceResponse>
          items.push(
            <TeamResult
              lastModifiedDateTime={DOMPurify.sanitize(
                teamItem.resource.lastModifiedDateTime
              )}
              description={DOMPurify.sanitize(teamItem.summary)}
              key={i}
              index={i}
              deviceSettings={deviceSettings}
              searchQuery={DOMPurify.sanitize(searchQuery)}
              from={DOMPurify.sanitize(
                teamItem.resource.from.emailAddress.name
              )}
              upn={DOMPurify.sanitize(
                teamItem.resource.from.emailAddress.address
              )}
              channelId={
                teamItem.resource.channelIdentity
                  ? teamItem.resource.channelIdentity.channelId
                  : ''
              }
              teamId={
                teamItem.resource.channelIdentity
                  ? teamItem.resource.channelIdentity.teamId
                  : ''
              }
              chatId={teamItem.resource.chatId}
              aadInfo={aadInfo}
              blockChatInformationFetch={!resultsFetched}
              messageId={teamItem.resource.id ? teamItem.resource.id : ''}
              synonymsApplied={extendedResults.synonymsApplied}
            />
          )
        }
      }
    } catch (error) {
      trackException('Error returning all items in ResultsOffice365.tsx', error)
    }
    return items
  }

  const firstItems = useMemo(
    () => returnAllItems('first'),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [results, intl, extendedResults, resultsFetched]
  )

  const restItems = useMemo(
    () => returnAllItems('rest'),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [results, intl, extendedResults, resultsFetched]
  )

  return (
    <Box>
      {deviceSettings.renderMobile ? (
        <ResultTeamsMobile
          {...props}
          resultsCount={resultsCount}
          searchQuery={searchQuery}
          blockChatInformationFetch={!resultsFetched}
          aadInfo={aadInfo}
          moreResultsAvailable={moreResultsAvailable}
          firstRender={firstRender}
          userSettings={userSettings}
          findConfigCognitiveSearchEnabled={
            findConfiguration.CognitiveSearchEnabled
          }
          cognitiveSearchEnabled={cognitiveSearchEnabled}
          showCognitiveMessage={showCognitiveMessage}
          setShowCognitiveMessage={setShowCognitiveMessage}
          setUserSettings={setUserSettings}
        />
      ) : (
        <Box className={resultClasses.container}>
          <Box
            id={'con-resultvertical-01'}
            className={`${resultClasses.resultsContainer} ${resultClasses.subResultsContainer}`}
          >
            {!hasErrorStore && (
              <Box className={o365Classes.spellCheck}>
                <SearchSuggestions
                  wildCardActive={true}
                  resultCount={resultsCount}
                  resultsFetchend={hasResultsBeenFetched}
                />
              </Box>
            )}
            {cognitiveSearchEnabled &&
              showCognitiveMessage &&
              findConfiguration.CognitiveSearchEnabled &&
              extendedResults.synonymsApplied &&
              extendedResults.synonymsApplied.length > 0 && (
                <CognitiveMessage
                  synonymsApplied={extendedResults.synonymsApplied}
                  setShowCognitiveMessage={setShowCognitiveMessage}
                  userSettings={userSettings}
                  setUserSettings={setUserSettings}
                />
              )}
            <O365FilterBar
              {...props}
              scope={'Teams'}
              datasources={datasources}
            />

            {!hasErrorStore && (
              <Box className={o365Classes.resultTableContainer}>
                <ReactPlaceholder
                  ready={hasResultsBeenFetched ? hasResultsBeenFetched : false}
                  type="text"
                  rows={4}
                  showLoadingAnimation={true}
                  className={resultClasses.placeholder}
                >
                  <Fade
                    in={hasResultsBeenFetched ? hasResultsBeenFetched : false}
                    timeout={600}
                  >
                    <Box id={'con-resultvertical-section-01'}>
                      {results && resultsCount > 0 && !firstRender && (
                        <FeaturedResults
                          featuredResults={extendedResults.featuredResults}
                        />
                      )}
                      {firstItems}
                    </Box>
                  </Fade>
                </ReactPlaceholder>
                {Number(DOMPurify.sanitize(currentPage)) === 1 && (
                  <LeftWidgetContainer
                    resultCount={resultsCount}
                    hasResultsBeenFetched={hasResultsBeenFetched}
                  />
                )}
                <ReactPlaceholder
                  ready={hasResultsBeenFetched}
                  type="text"
                  rows={4}
                  showLoadingAnimation={true}
                  className={resultClasses.placeholder}
                >
                  <Fade in={hasResultsBeenFetched} timeout={600}>
                    <Box id={'con-resultvertical-section-02'}>
                      {restItems}
                      {resultsCount != null && resultsCount > 0 && (
                        <Pagination
                          {...props}
                          totalRecords={
                            moreResultsAvailable
                              ? resultsCount + 1
                              : resultsCount
                          }
                          pageLimit={20}
                          pageNeighbours={4}
                        />
                      )}
                    </Box>
                  </Fade>
                </ReactPlaceholder>
                <ReactPlaceholder
                  ready={hasResultsBeenFetched ? hasResultsBeenFetched : false}
                  type="text"
                  rows={4}
                  showLoadingAnimation={true}
                  className={resultClasses.placeholder}
                >
                  <Box />
                </ReactPlaceholder>
                <ReactPlaceholder
                  ready={hasResultsBeenFetched ? hasResultsBeenFetched : false}
                  type="text"
                  rows={4}
                  showLoadingAnimation={true}
                  className={resultClasses.placeholder}
                >
                  <Box />
                </ReactPlaceholder>
              </Box>
            )}

            {hasErrorStore && (
              <ErrorPage
                dataSource="office365"
                origin={'teams'}
                isContainerWrapped={true}
              />
            )}
          </Box>
          {!!searchQuery && (
            <Box className={resultClasses.advertisementContainer}>
              <RightTopWidgetContainer
                searchQuery={searchQuery}
                scope={'office365_teams'}
              />
              <RightWidgetContainer />
            </Box>
          )}
        </Box>
      )}
    </Box>
  )
}

const mapStateToProps = (state: Store) => {
  return {
    extendedResults: ResultsOffice365Store.selectors.getExtendedResults(
      state,
      'teams'
    ),
    results: ResultsOffice365Store.selectors.getResults(state, 'teams'),
    resultsCount: ResultsOffice365Store.selectors.getResultCount(
      state,
      'teams'
    ),
    executionTime: ResultsOffice365Store.selectors.getExecutionTime(
      state,
      'teams'
    ),
    hasResultsBeenFetched:
      ResultsOffice365Store.selectors.hasResultsBeenFetched(state, 'teams'),
    hasErrorStore: ResultMetaDataStore.selectors.getDataSourceHasError(
      state,
      'office365teams'
    ),
    currentPage: ResultsOffice365Store.selectors.getCurrentPage(state, 'teams'),
    userSettings: UserSettingsStore.selectors.getUserSettings(state),
    deviceSettings: SettingsStore.selectors.getDeviceSettings(state),
    aadInfo: AuthStore.selectors.getAADInfo(state),
    findConfiguration: SettingsStore.selectors.getFindConfiguration(state),
    useCognitiveSearch: SettingsStore.selectors.getUseCognitiveSearch(state)
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    fetchResultsOffice365: (
      searchQuery: string,
      currentPage: number,
      deviceSettings: IDeviceSetting,
      userSettings: IUserSetting,
      filters: any,
      findConfiguration: IFindConfiguration
    ) =>
      dispatch(
        ResultsOffice365Store.actions.fetchResultsOffice365(
          searchQuery,
          currentPage,
          userSettings,
          deviceSettings,
          findConfiguration,
          filters,
          'teams',
          false,
          true,
          true
        )
      ),
    setUserSettings: (userSettings: IUserSetting) =>
      dispatch(UserSettingsStore.actions.upSertUserSettings(userSettings))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ResultTeams)
